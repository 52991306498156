import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import FullWidth from "./layouts/FullWidth.vue";

Vue.component('fullwidth-layout', FullWidth);


import './../node_modules/bulma/css/bulma.css';


// Fontawesome
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)
library.add(faCheck)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
//end of fontawesome setup

// Import the Auth0 configuration
import { domain, clientId, audience } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

// Global functions
Vue.mixin({
  methods: {
    async getAccessToken() {
      this.accessToken = await this.$auth.getTokenSilently()
    },
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
